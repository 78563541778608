//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import EmptyMessage from '@/components/EmptyMessage'
export default {
    name: 'GroupInvite',
    components: {
        EmptyMessage
    },
    data() {
        return {
            isValidInvite: true,
            invite: this.$route.params.invite,
            parsedInviteDate: moment(this.$route.params.invite.invite_date).format('LL'),
            parsedInviteTime: moment(this.$route.params.invite.invite_date).format('LT'),
            isAccepting: false,
            isDeclining: false
        }
    },
    created() {
        if (this.invite.inviter_name == '') {
            this.isValidInvite = false;
        }
        if (!this.invite.id) {
            this.isValidInvite = false;
        }
        if (!this.invite.name) {
            this.isValidInvite = false;
        }
    },
    computed: {
        entityName() {
            if (this.invite.entity_type_id == 1) {
                return 'Group'
            } else if (this.invite.entity_type_id == 2) {
                return 'Event'
            } else if (this.invite.entity_type_id == 3) {
                return 'Store'
            } else {
                return 'Unknown'
            }
        }
    },
    methods: {
      redirect(id, type) {
          switch (type) {
              case 'Group':
                  this.$router.push({
                    name: 'Group', params: {
                        id: id
                    }
                  })
              break;
              case 'Event':
                  this.$router.push({
                    name: 'Event', params: {
                        id: id
                    }
                  })
              break;
              case 'Store':
                  this.$router.push({
                    name: 'Group', params: {
                        id: id
                    }
                  })
              break;
          }
      },
      accept() {
        this.isAccepting = true;

        this.$http({
          url: 'entities/' + this.$route.params.id + '/accept',
          method: 'POST'
        })
        .then((response) => {
          this.isAccepting = false;
          switch (response.data['status']) {
              case 'invite_accepted':
                this.redirect(
                  this.$route.params.id,
                  this.entityName
                );
              break;
              case 'no_invites':
                this.$router.push({name: 'Profile'});
                this.$q.notify({
                  message: 'The invite does not exist or is no longer available.',
                  color: 'primary'
                });
              break;
              case 'already_member':
                this.redirect(this.$route.params.id, this.entityName);
              break;
              default:
                this.$q.notify({
                    message: 'An error has occurred while trying to accept. Please try again later.',
                    color: 'negative'
                });
              break;
          }
        })
      },

      decline() {
        this.isDeclining = true;
        this.$http({
          url: 'entities/' + this.$route.params.id + '/decline',
          method: 'POST'
        }).then((response) => {
          this.isDeclining = false;
          if (response.data['status'] == 'invite_declined') {
            this.$router.push({name: 'Profile', params: {id: this.$store.state.user.id}});
            this.$q.notify({
              message: 'You have declined the invite.',
              color: 'primary'
            });
          } else {
            this.$q.notify({
              message: 'An error has occurred while trying to accept. Please try again later.',
              color: 'negative'
            });
          }
        });        
      }
    }
}
